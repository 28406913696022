*, *::before, *::after{
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
}
.ba-widget-overlay{background-color: #000000ab;}
.ba-widget-overlay .ba_widget_main_design{overflow: unset;}
.ba_widget_main_design {
  margin: 0 auto;
  min-height: 350px;
  overflow: hidden;
}
@media (max-width: 550px) {
  .ba_widget_main_design.regular,
  .ba_widget_main_design.large{width: 99%; margin-top: 10%}
  .ba_widget_main_design.sms_enabled{margin-top: 1%;}
}
@media (min-width: 550px) {
  .ba_widget_main_design.regular{width: 600px; margin-top: 6%}
  .ba_widget_main_design.large{width: 750px; margin-top: 6%}
  .ba_widget_main_design.sms_enabled{margin-top: 3%;}
}
#close_ba_widget{top: 20px; right: 20px; position: absolute; z-index: 1;}
#close_ba_widget:focus-visible{outline: none;}
#close_ba_widget svg:hover{opacity: 1;}
#close_ba_widget svg{float: right; fill: #535453; width: 20px; opacity: 0.7}
#close_ba_widget svg:hover{fill: #d1d1d1;}
.close_ba_widget{cursor: pointer;}
.ba_widget_logo_holder{text-align: inherit;; margin-bottom: 15px;}
.ba_widget_logo_holder svg, .ba_widget_logo_holder img{height: 60px; width: 60px;}
.ba_widget_content_holder{text-align: inherit; word-break: break-word;}
.ba_widget_parent{position: relative; min-height: 350px;}
.ba_widget_parent.background{
  background-size: cover;
  background-position: center center;
}
.ba_widget_parent.background .ba_widget_content,
.ba_widget_parent.none .ba_widget_content{
  padding: 25px 40px 25px 40px;
}
.ba_widget_parent.left .ba_widget_content{
  width: 60%;
  margin-left: auto;
  padding: 20px;
}
.ba_widget_parent.right .ba_widget_content{
  width: 60%;
  margin-right: auto;
  padding: 20px;
}
.ba_widget_bg{
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0px;
  width: 40%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ba_widget_left_content{
  left: 0px;
}
.ba_widget_right_content{
  right: 0px;
}
.ba_widget_copy_discount{position: relative; display: flex; align-items: center;}
.ba_widget_copy_discount svg.ba_widget_copy{
  position: absolute;
  top: 13px;
  right: 15px;
  cursor: pointer;
  opacity: 0.7;
}
.ba_widget_copy_discount svg.ba_widget_copy:hover{opacity: 1;}
#ba_widget_email_input.error{border: 1px solid #ff0101;}
#ba_widget_discount_code_input{font-size: 17px !important; text-align: center; letter-spacing: 1px;}
@media (max-width: 550px) {
  #ba_widget_discount_code_input{
    padding: .5rem .5rem !important;
  }
}
#ba_widget_email_input, #ba_widget_phone_input, #ba_widget_discount_code_input, #ba_widget_first_name_input, #ba_widget_last_name_input{
  height: 50px;
  font-size: 19px;
  margin-bottom: 10px !important;
  position: relative;
  padding: .5rem 1.5rem;
  background-color: #fff;
  border: .1rem solid #c4cdd5;
  border-radius: 4px;
  color: #31373d;
  display: block;
  width: 100%;
  line-height: 2.4rem;
  min-width: 7.5rem;
  vertical-align: baseline;
  max-width: 100%;
  box-shadow: 0 0 0 1px #00000000, 0 1px 0 0 #3a2c2c0d;
  font-family: inherit;
}
#ba_widget_cta_button:disabled{
  pointer-events: none;
}
#ba_widget_cta_button{
  margin: 10px 0 10px 0;
  width: 100%;
  height: 50px;
  font-size: 21px;
  font-family: inherit;
  position: relative;
  display: inline-block;
  border: .1rem solid #fff0;
  border-radius: .3rem;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  text-transform: none;
  font-weight: normal;
  vertical-align: middle;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
}
#ba_widget_cta_button:hover {font-size: 20px; cursor: pointer;}
#ba_widget_cta_button:after {
  content: "";
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

#ba_widget_cta_button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

div.widget-name-holder{display:flex;}

.ba_initial_state_button{margin-top: 0;}
.ba_success_state_button{margin-bottom: 40px;}
.ba_initial_state_title, .ba_success_state_title{
  font-weight: 600;
  line-height: 33px;
}
@media (min-width: 550px) {
  .ba_initial_state_title, .ba_success_state_title{
    font-size: 38px;
  }
  .ba_initial_state_body, .ba_success_state_body{
    font-size: 21px;
  }
}
@media (max-width: 550px) {
  .ba_initial_state_title, .ba_success_state_title{
    font-size: 30px;
  }
  .ba_initial_state_body, .ba_success_state_body{
    font-size: 19px;
  }
}
.ba_initial_state_body, .ba_success_state_body{
  font-weight: normal;
  line-height: normal;
  margin-bottom: 20px;
}
.ba_widget_footer{
  text-align: inherit;
  width: 100%;
}
.ba_initial_state_dismiss_text:hover{text-decoration: underline;}
.ba_initial_state_dismiss_text{
  font-size: 18px;
  display: inline;
  margin: 0 auto;
}
.ba_initial_state_footer_text{
  font-size: 15px;
  margin-top: 25px;
}
.ba_initial_state_sms_agreement{
  padding: 20px 5px;
  text-align: justify;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
}
.ba_link_color{
  text-decoration: none;
}
.ba_link_color:hover{
  text-decoration: underline;
}
.ba-messages-wrapper{
  margin-bottom: 10px;
}
.ba_widget_error{
  font-family: inherit;
  font-size: 14px;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.ba-copy-button:hover:after {
	opacity: 1
}
.ba-copy-button:after {
	background-repeat: no-repeat;
	left: 0;
	content: "";
	opacity: .7;
	top: 0;
	background-position: 50%;
	bottom: 0;
	right: 0;
	position: absolute;
	transition: background-color .2s ease-in-out,opacity .2s ease-in-out;
}
.ba-copy-button {
  background-color: #fff;
  height: 40px;
  width: 45px;
  text-align: left;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  position: absolute;
  right: 10px;
  top: 6px;
}
.ba-copy-success:before {
  content: "";
	background-color: inherit;
	width: 42px;
	height: 36px;
	z-index: 1;
	left: 0;
  top: -10px;
  right: 0;
	bottom: 0;
	margin: auto;
	display: block;
	position: absolute;
	opacity: 0.7;
	background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z' fill='%238d8d8f' fill-rule='non-zero' /%3E%3C/svg%3E")
}
.ba-copy:after {
	background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 24 24'%3E%3Cpath fill='%238d8d8f' d='M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2z'/%3E%3C/svg%3E")
}

/* IntlTelInput overrides */
.iti__flag-container{bottom: 10px !important;}
.iti{width: 100%;}
.iti__selected-dial-code {font-size: 18px;}
#ba_widget_phone_input.intlTelInput{padding-left: 89px !important;}

.powered_by_rivo{
  width: 100%;
  padding: 25px 0;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-family: Arial;
  text-align: center;
}
.powered_by_rivo a{
  font-weight: bold;
  color: #35a9f9;
  text-decoration: none;
}
.powered_by_rivo a:hover{
  text-decoration: underline;
}

.ba-widget-main{
  transform: scale(1);
  animation: widgetInAnimation .2s cubic-bezier(0.17, 0.85, 0.45, 1) forwards;
}
@keyframes widgetInAnimation {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}
